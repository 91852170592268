import React, { createContext, ReactNode, useContext } from 'react';
import { repositoryGetter, TUnionRepo, TLibProp } from '@netvision/lib-api-repo'

export type TApiRepositoryProviderProps = {
  lib?: TLibProp
  children: ReactNode
}

const ApiRepositoryContext = createContext({ api: {} as TUnionRepo, lib: {} as TLibProp | undefined })

export const useApiRepository = () => useContext(ApiRepositoryContext)

export const ApiRepositoryProvider = ({ children, lib }: TApiRepositoryProviderProps) => {
  const api = repositoryGetter(lib)
  return (
    <ApiRepositoryContext.Provider value={{ api, lib }}>
      {children}
    </ApiRepositoryContext.Provider>
  )
}
