/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FileUpload } from 'primereact/fileupload';
import { TFileUploadHandler, TUploadState } from '../../types';
import { useEffect } from 'react';

type FileUploaderProps = {
  fileUploadAction: string;
  fileUploadText: string;
  uploadState: TUploadState;
  uploadFile: TFileUploadHandler['uploadFile'];
}

export const FileUploader = ({
  fileUploadAction,
  fileUploadText,
  uploadState,
  uploadFile
}: FileUploaderProps) => {

  const fileUploadElemsActivate = (event: any) => {
    event.currentTarget?.classList.add("active");
  }

  const fileUploadElemsDeactivate = (event: any) => {
    event.currentTarget?.classList.remove("active");
  }

  useEffect(() => {
    const fileUploadElems = document.getElementsByClassName("p-fileupload");
    Array.from(fileUploadElems).forEach((el) => {
      el.addEventListener("dragenter", fileUploadElemsActivate);
      el.addEventListener("mousemove", fileUploadElemsDeactivate);
    });

    return () => {
      Array.from(fileUploadElems).forEach((el) => {
        el.removeEventListener("dragenter", fileUploadElemsActivate);
        el.removeEventListener("mousemove", fileUploadElemsDeactivate);
      });
    }
  }, []);

  return (
    <FileUpload
      multiple={false}
      chooseLabel={fileUploadAction}
      emptyTemplate={fileUploadText}
      itemTemplate={uploadState.isReady && fileUploadText}
      progressBarTemplate={uploadState.isReady && <div></div>}
      css={fileUploadCSS}
      className="p-flex p-flex-column"
      onSelect={(event) => uploadFile(event.files[0])}
    />
  );
}

const fileUploadCSS = css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &.active {
    .p-fileupload-buttonbar {
      z-index: 10;
    }
  }

  .p-fileupload-buttonbar {
    padding: 0;
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 30;

    [aria-label="Upload"],
    [aria-label="Cancel"] {
      display: none;
    }
  }

  .p-fileupload-content {
    text-align: center;
    padding: 0;
    padding: 1.25rem 2rem calc(2rem + var(--input-height) + 30px);
    flex: none;
    width: 100%;
    border: 2px dashed #404951;
    transition: border-color 0.2s ease;
    border-radius: 10px;
    font-size: 16px;
    position: relative;
    z-index: 20;

    &.p-fileupload-highlight {
      border-color: var(--primary-color);
      transition: border-color 0.2s ease;
    }

    .p-progressbar {
      display: none;
    }
  }

  .p-fileupload-row {

    & > div {
      
      &:nth-of-type(2) {
        display: none;
      }

      &:last-of-type {
        width: auto;
        flex: 0;
      }
    }
  }
`
