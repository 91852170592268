export const localesEN = {
  title: 'License',
  success: 'Success',
  certificate: {
    title: 'Certificate',
    fileUploadText: 'Drag the certificate file from your computer or click the «upload file» button',
    fileUploadAction: 'Upload file',
    tableLabel: 'Actual sertificate info',
    responseKey: 'Download response key',
    successfulUpload: 'Certificate has been uploaded successfully',
    successfulValidated: 'Certificate has been verified'
  },
  activationKey: {
    title: 'Activation key',
    fileUploadText: 'Drag the activation key file from your computer or click the «upload file» button',
    fileUploadAction: 'Upload file',
    tableLabel: 'Actual activation info',
    successfulUpload: 'Activation key has been uploaded successfully',
    successfulValidated: 'Activation key has been verified'
  },
  features: {
    title: 'Features'
  },
  errors: {
    error: 'Error',
    errorFileUploadSummary: 'Loading error',
    errorFileUploadMessage: 'The file has expired, or the file contains incorrect data',
    wrongCertificateFormat: 'Only files with the {param} extension are allowed'
  },
  infoTable: {
    fileName: 'Filename',
    product: 'Product',
    customer: 'Customer',
    licenseId: 'License ID',
    activationId: 'Activation ID',
    created: 'Date of creation',
    notAfter: 'Expiration date',
    unknown: 'Unknown or lost'
  },
  featuresTable: {
    entityType: 'Entity type',
    id: 'Feature metadata ID',
    title: 'Feature metadata title',
    value: 'Value',
    absent: 'Absent',
    active: 'Active',
    inactive: 'Inactive'
  }
}
