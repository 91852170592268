import { useToastRef } from './useToastRef'
import { useLocale } from '../hooks/useLocale'

export const useFileExtensionChecker = () => {
  const toast = useToastRef()
  const { $t } = useLocale()
  const isExtValid = (file: File, ext: string) => {
    if (file.name.split(".").pop() !== ext) {
      toast.current?.show({
        severity: 'error',
        summary: $t('errors.error'),
        detail: $t('errors.wrongCertificateFormat', `.${ext}`)
      })
      return false
    }
    return true
  }

  return [isExtValid]
}
