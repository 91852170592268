/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ILicenseServerInfoFeature, IServerInfo } from '@netvision/lib-api-repo';
import { useLocale } from '../hooks/useLocale';
import { useToastRef } from '../hooks/useToastRef';
import { useApiRepository } from '../hooks/useApiRepository';
import { useDateParser } from '../hooks/useDateParser';
import { Header } from './Header';
import { Certificate } from './Certificate';
import { ActivationKey } from './ActivationKey';
import { ActivationFeatures } from './ActivationFeatures'
import { useEffect, useState } from 'react';
import { TActivationInfo, TCertificateInfo, TUploadState } from '../types';

export const Main = () => {
  const toast = useToastRef()
  const { $t } = useLocale()
  const { api } = useApiRepository()
  const [localizeDate] = useDateParser()
  const [isCertificateValid, setIsCertificateValid] = useState(true)
  const [certificateUploadState, setCertificateUploadState] = useState<TUploadState>({ isReady: false, filename: '' })
  const [activationUploadState, setActivationUploadState] = useState<TUploadState>({ isReady: false, filename: '' })
  const [certificateInfo, setSertificateInfo] = useState<TCertificateInfo>()
  const [activationInfo, setActivationInfo] = useState<TActivationInfo>()
  const [activationFeatures, setActivationFeatures] = useState<Omit<ILicenseServerInfoFeature, 'schemaId' | 'type'>[]>()

  const errorHandler = (error: Error) => {
    console.error(error)
    toast.current?.show({
      severity: 'error',
      summary: $t('errors.errorFileUploadSummary'),
      detail: $t('errors.errorFileUploadMessage')
    })
  }
  
  const uploadCertificate = (file: File) => {
    const data = { X509File: file }
    const config = {
      id: 'certificate',
      type: 'Realm'
    }
    api.fileUpload && api.fileUpload<TCertificateInfo>(data, config)
      .then((response) => {
        setSertificateInfo(response)
        setCertificateUploadState({ isReady: true, filename: response.fileName })
        toast.current?.show({
          severity: 'success',
          summary: $t('success'),
          detail: $t('certificate.successfulUpload')
        })
        validateUploadedEntity('certificate')
      })
      .catch((error: Error) => errorHandler(error));
  }

  const uploadActivationKey = (file: File) => {
    const data = { activationFile: file }
    const config = {
      id: 'activation',
      type: 'Realm'
    }
    api.fileUpload && api.fileUpload<TActivationInfo>(data, config)
      .then((response) => {
        delete response.product
        delete response.customer
        delete response.licenseId
        setActivationInfo(response)
        setActivationUploadState({ isReady: true, filename: response.fileName })
        toast.current?.show({
          severity: 'success',
          summary: $t('success'),
          detail: $t('activationKey.successfulUpload')
        })
        validateUploadedEntity('activationKey')
      })
      .catch((error: Error) => errorHandler(error));
  }

  const validateUploadedEntity = (entity: string) => {
    api.fileUpload && api.fileUpload({}, {
      id: 'validation',
      type: 'Realm'
    })
      .then(() => {
        getServerInfo()
        toast.current?.show({
          severity: 'success',
          summary: $t('success'),
          detail: $t(`${entity}.successfulValidated`)
        })
      })
      .catch((error: Error) => console.error(error));
  }

  const parseCertificateInfo = (response: IServerInfo) => {
    return {
      fileName: response.licenseInfo.certificateFile?.replace('/license/', '') || $t('infoTable.unknown'),
      get product() {
        return this.fileName?.split('=')?.[0] || $t('infoTable.unknown')
      },
      get customer() {
        return this.fileName?.split('=')?.[1] || $t('infoTable.unknown')
      },
      get licenseId() {
        return this.fileName?.split('=')?.[2] || $t('infoTable.unknown')
      },
      get created() {
        return localizeDate(this.fileName?.split('=')?.[3]?.split('--')?.[0]) || $t('infoTable.unknown')
      },
      notAfter: localizeDate(response.licenseInfo.certificateExpirationUtc?.split('T')?.[0]) || $t('infoTable.unknown')
    }
  }

  const parseActivationInfo = (response: IServerInfo) => {
    return {
      fileName: response.licenseInfo.activationFile?.replace('/license/', '') || $t('infoTable.unknown'),
      get created() {
        return localizeDate(this.fileName.split('=')?.[4]?.split('--')?.[0]) || $t('infoTable.unknown')
      },
      get activationId() {
        return this.fileName.split('=')?.[3]?.split('--')?.[0] || $t('infoTable.unknown')
      },
      notAfter: localizeDate(response.licenseInfo?.activationExpirationUtc?.split('T')[0]) || $t('infoTable.unknown')
    }
  }

  const parseActivationFeatures = (response: IServerInfo) => {
    if ('features' in response.licenseInfo) {
      return response.licenseInfo.features?.map(({
        constraint, entityType, id, title, value
      }) => ({
        constraint, entityType, id, title, value
      }))
    }
    return []
  }

  const getServerInfo = () => {
    api.getEntity<IServerInfo>({
      id: 'serverInfo',
      type: 'Client'
    })
      .then(({ entity }) => {
        setSertificateInfo(parseCertificateInfo(entity))
        setActivationInfo(parseActivationInfo(entity))
        setActivationFeatures(parseActivationFeatures(entity))
        setCertificateUploadState({
          isReady: true,
          filename: entity.licenseInfo.certificateFile.replace('/license/', '')
        })
        setActivationUploadState({
          isReady: true,
          filename: entity.licenseInfo.activationFile.replace('/license/', '')
        })

        setIsCertificateValid(entity.licenseInfo.isCertificateExpired === false)
      })
  }

  useEffect(() => {
    getServerInfo()
  }, [])

  return (
    <div style={{ overflow: 'hidden' }}>
      <main css={mainCSS}>
        <Header title={$t('title')} />
        <div css={containerCSS}>
          {(certificateInfo && 'fileDownload' in api) &&
            <Certificate
              isValid={isCertificateValid}
              certificateInfo={certificateInfo}
              uploadState={certificateUploadState}
              uploadFile={(file) => uploadCertificate(file)}
              downloadFile={(payload) => api.fileDownload && api.fileDownload(payload)}
            />
          }
          {activationInfo &&
            <ActivationKey
              activationInfo={activationInfo}
              uploadState={activationUploadState}
              uploadFile={(file) => uploadActivationKey(file)}
            />
          }
          {activationFeatures &&
            <ActivationFeatures features={activationFeatures} />
          }
        </div>
      </main>
    </div>
  )
}

const mainCSS = css`
  margin-top: calc(94rem/var(--bfs));
  margin-right: calc(30rem/var(--bfs));
  margin-left: calc(30rem/var(--bfs));
  margin-bottom: calc(30rem/var(--bfs));
  height: calc(100vh - 124rem/var(--bfs));
  width: calc(100vw - 60px);
  position: relative;
`

const containerCSS = css`
  margin-top: calc(30rem/var(--bfs));
  height: calc(100% - 7rem);
  overflow: auto;
  display: grid;
  gap: calc(28rem/var(--bfs));
  grid-template-columns: repeat(3, 1fr);
`

export const infoTable = css`
  width: 100%;
  margin-top: calc(14rem/var(--bfs));
  tr {
    td {
      padding: 0.5rem 0.75rem;
      border-bottom: 1px solid var(--secondary-color-alt-1);
      &:first-of-type {
        border-right: 1px solid var(--secondary-color-alt-1);
        white-space: nowrap;
      }
    }
    &:last-of-type {
      td {
        border-bottom: 0;
      }
    }
  }
`
