/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useMemo } from 'react'
import { useLocale } from '../hooks/useLocale'
import { FieldLabel } from './common/FieldLabel'
import { FileUploader } from './common/FileUploader'
import { TActivationInfo, TFileUploadHandler, TUploadState } from '../types'
import { useFileExtensionChecker } from '../hooks/useFileExtensionChecker'
import { infoTable } from './Main'

type ActivationKeyProps = TFileUploadHandler & {
  uploadFile: (file: File) => void
  activationInfo: TActivationInfo
  uploadState: TUploadState
}

export const ActivationKey = ({ uploadFile, activationInfo, uploadState }: ActivationKeyProps) => {
  const { $t } = useLocale()
  const [isExtValid] = useFileExtensionChecker()

  const uploadActivationKey = (file: File) => {
    isExtValid(file, 'jws') && uploadFile(file)
  }
  
  const activationInfoTable = useMemo(() => {
    return Object.entries(activationInfo).reduce<{key: string; value: string}[]>((acc, [key, value]) => {
      const localeKey = $t(`infoTable.${key}`)
      if (localeKey) {
        acc.push({ key: localeKey, value })
      }
      return acc
    }, [])
  }, [activationInfo])

  return (
    <section>
      <FieldLabel
        title={$t('activationKey.title')}
      >
        <FileUploader
          fileUploadAction={$t('activationKey.fileUploadAction')}
          fileUploadText={$t('activationKey.fileUploadText')}
          uploadFile={uploadActivationKey}
          uploadState={uploadState}
        />

        <FieldLabel
          title={$t('activationKey.tableLabel')}
          type="sub"
        >
          <table css={infoTable}>
            <tbody>
              {activationInfoTable.map(({ key, value }, index) => (
                <tr key={index}>
                  <td>{key}</td>
                  <td>{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </FieldLabel>
      </FieldLabel>
    </section>
  )
}
