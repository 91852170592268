/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useCallback } from 'react';
import { useLocale } from '../hooks/useLocale';
import { FieldLabel } from './common/FieldLabel';
import { ILicenseServerInfoFeature } from '@netvision/lib-api-repo/dist/src/types';
import { infoTable } from './Main'

type ActivationFeaturesProps = {
  features?: Omit<ILicenseServerInfoFeature, 'schemaId' | 'type'>[];
}

export const ActivationFeatures = ({ features }: ActivationFeaturesProps) => {
  const { $t } = useLocale()

  const parseTableValue = (value: unknown) => {
    switch(value) {
      case null:
        return 'absent'
      case true:
        return 'active'
      case false:
        return 'inactive'
      default:
        return String(value)
    }
  }

  const activationFeaturesInfoTable = useCallback((feature: Omit<ILicenseServerInfoFeature, 'schemaId' | 'type'>) => {
    return Object.entries(feature).reduce<{key: string; value: string}[]>((acc, [key, value]) => {
      const localeKey = $t(`featuresTable.${key}`)
      if (localeKey) {
        const parsedValue = parseTableValue(value)
        acc.push({
          key: localeKey,
          value: `${$t(`featuresTable.${parsedValue}`) || parsedValue}`
        })
      }
      return acc
    }, [])
  }, [features])

  return (
    <section>
      <FieldLabel
        title={$t('features.title')}
      >
        {(features && features.length > 0) &&
          features.map((feature, index) => (
            <table css={infoTable} key={index}>
              <tbody>
                {activationFeaturesInfoTable(feature).map(({ key, value }, index) => (
                  <tr key={index}>
                    <td>{key}</td>
                    <td>{value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ))
        }
      </FieldLabel>
    </section>
  )
}