/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useMemo } from 'react'
import { useLocale } from '../hooks/useLocale'
import { FieldLabel } from './common/FieldLabel'
import { FileUploader } from './common/FileUploader'
import { Button } from 'primereact/button'
import { TCertificateInfo, TFileUploadHandler, TUploadState } from '../types'
import { IEntity } from '@netvision/lib-api-repo'
import { useFileExtensionChecker } from '../hooks/useFileExtensionChecker'
import { infoTable } from './Main'

type CertificateProps = TFileUploadHandler & {
  uploadFile: (file: File) => void
  downloadFile: (payload: IEntity) => void
  certificateInfo: TCertificateInfo
  uploadState: TUploadState
  isValid: boolean
}

export const Certificate = ({
  uploadFile,
  downloadFile,
  certificateInfo,
  uploadState,
  isValid
}: CertificateProps) => {
  const { $t } = useLocale()
  const [isExtValid] = useFileExtensionChecker()

  const uploadCertificate = (file: File) => {
    isExtValid(file, 'crt') && uploadFile(file)
  }

  const certificateInfoTable = useMemo(() => {
    return Object.entries(certificateInfo).reduce<{key: string; value: string}[]>((acc, [key, value]) => {
      const localeKey = $t(`infoTable.${key}`)
      if (localeKey) {
        acc.push({ key: localeKey, value })
      }
      return acc
    }, [])
  }, [certificateInfo])

  return (
    <section>
      <FieldLabel
        title={$t('certificate.title')}
      >
        <FileUploader
          fileUploadAction={$t('certificate.fileUploadAction')}
          fileUploadText={$t('certificate.fileUploadText')}
          uploadFile={uploadCertificate}
          uploadState={uploadState}
        />

        <FieldLabel
          title={$t('certificate.tableLabel')}
          type="sub"
        >
          <table css={infoTable}>
            <tbody>
              {certificateInfoTable.map(({ key, value }, index) => (
                <tr key={index}>
                  <td>{key}</td>
                  <td>{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </FieldLabel>
      </FieldLabel>
      <Button
        style={{ marginTop: 'calc(28rem/var(--bfs))' }}
        label={$t('certificate.responseKey')}
        onClick={() => downloadFile({
          id: 'hardwareId',
          type: 'Realm',
          config: {
            responseType: "arraybuffer",
            headers: {
              "Content-Type": "application/octet-stream",
              "Content-Disposition": "attachment",
            },
          }
        })}
        disabled={!isValid || !certificateInfoTable.length}
      />
    </section>
  )
}
