import { useLocale } from './useLocale';

export const useDateParser = () => {
  const { locale } = useLocale()

  const localizeDate = (date?: string) => {
    if (typeof date !== 'string' || isNaN(Date.parse(date))) return date
    return new Date(date).toLocaleDateString(`${locale}-${locale.toUpperCase()}`)
  }

  return [localizeDate]
}
