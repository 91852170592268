export const localesRU = {
  title: 'Лицензия',
  success: 'Готово!',
  certificate: {
    title: 'Сертификат',
    fileUploadText: 'Перетащите файл сертификата с компьютера или нажмите кнопку «загрузить файл»',
    fileUploadAction: 'Загрузить файл',
    tableLabel: 'Информация о текущем сертификате',
    responseKey: 'Скачать аппаратный идентификатор',
    successfulUpload: 'Сертификат успешно загружен',
    successfulValidated: 'Сертификат прошел проверку'
  },
  activationKey: {
    title: 'Активационный токен',
    fileUploadText: 'Перетащите файл активационного токена с компьютера или нажмите кнопку «загрузить файл»',
    fileUploadAction: 'Загрузить файл',
    tableLabel: 'Информация о текущей активации',
    successfulUpload: 'Активационный токен успешно загружен',
    successfulValidated: 'Активационный ключ прошел проверку'
  },
  features: {
    title: 'Опции'
  },
  errors: {
    error: 'Ошибка',
    errorFileUploadSummary: 'Ошибка загрузки',
    errorFileUploadMessage: 'Истек срок действия файла, или файл содержит некорректные данные',
    wrongCertificateFormat: 'Допускаются только файлы с расширением {param}'
  },
  infoTable: {
    fileName: 'Имя файла',
    product: 'Продукт',
    customer: 'Клиент',
    licenseId: 'ID лицензии',
    activationId: 'ID активации',
    created: 'Дата создания',
    notAfter: 'Срок действия',
    unknown: 'Неизвестно или утеряно'
  },
  featuresTable: {
    entityType: 'Тип сущности',
    id: 'ID метаданных опции',
    title: 'Название',
    value: 'Значение',
    absent: 'Отсутствует',
    active: 'Активно',
    inactive: 'Неактивно'
  }
}
